<for-whom>
    <article>
        <h2>למי זה מיועד</h2>
        <section>
            <div>
                <h2>יחידים</h2>
                <ul>
                    <li>אנשים המחפשים כלים לשיפור איכות החיים הפנימית ובכלל</li>
                    <li>אנשים המחפשים את שיפור הבהירות, התבונה, השלווה והאיזון</li>
                    <li>אנשים המחפשים ללכת צעד נוסף, לכיוון התפתחות פנימית, אולי אפילו רוחנית</li>
                    <li>אנשים המחפשים לשפר את יכולת ההתמודדות שלהם עם לחצים בבית, בעבודה ובכלל</li>
                    <p>
                        <a href="/plans">ראו תכניות ומחירים</a>
                    </p>
                </ul>
            </div>
            <div>
                <h2>מתרגלים רציניים של דהרמה</h2>
                <ul>
                    <li>זה המסלול המחייב ביותר.</li>
                    <li>לימוד והשבחה של התרגול הבודהיסטי</li>
                    <li>בודהיזם טיבטי בעיקר</li>
                    <li>כולל שאמטה, ויפאסנה, טבע-התודעה, אמצעים מיומנים, העמקה ועוד</li>
                    <li>מנטורינג דהרמי, על בסיס הבודהיזם הטיבטי בעיקר</li>
                    <li>תשלום על פי היכולת והנדיבות של המתרגל (דהאנה)</li>
                </ul>
            </div>
            <div>
                <h2>משפחות, זוגות ונוער</h2>
                <ul>
                    <li> משפחות המעונינות לשפר את הדינאמיקה המשפחתית, בין בני הזוג, עם הילדים, ובכלל</li>
                    <li> משפחות נורמטיביות, שרוצות ביחד לקחת את חיי המשפחה למקומות המדהימים שאפשר להגיע אליהם, ושמעטים
                        זוכים
                        לעשות כן
                    </li>
                    <li>הורים המחפשים את האי הפנימי השקט בלב ההמולה של הבית</li>
                    <li>הורים המעוניינים לשלוח את
                        <a href="/lib/youth" target="_blank">
                        ילדהם הבוגרים
                        </a>
                        למשהו שיעניק להם מתנה לחיים </li>
                    <div class="extra">
                    ראו גם <a href="https://www.lojong-studio.com/lib/relationships1">יחסים וזוגיות לאור ההתפתחות
                    הרוחנית</a>
                    </div>
                    <p>
                    <a href="/plans">ראו תכניות ומחירים</a>
                    </p>
                </ul>
            </div>
            <div>
                <h2>ארגונים, צוותים, קבוצות</h2>
                <ul>
                    <li>ארגונים המעונינים לשפר את היצירתיות והפרודוקטיביות של צוותי חשיבה או תכנון</li>
                    <li>ארגונים וצוותים המעוניינים לשפר את האווירה הצוותית</li>
                    <li>ארגונים המעוניינים להעזר בעין חיצונית, מנוסה על מנת לטייב תהליכי ניהול, ביצוע ותכנון</li>
                    <li>ארגונים וצוותים שרוצים להוסיף תהליך של well-being לטובת החברים בהם</li>
                    <li>קבוצות שרוצות להתפתח, ללמוד, להתעצם ולגדול ביחד</li>
                    <li>יתרונות מיוחדים, נוספים להייטק</li>
                    <div>ראו גם את המאמר
                        <a href="https://www.lojong-studio.com/lib/hitech1">חדשנות ורגישות בתקופת משבר - מפגש קסום בין
                            הבודהיזם להייטק</a>
                    </div>

                    <p>
                        <a href="/plans">ראו תכניות ומחירים</a>
                    </p>

                </ul>
            </div>
            <div>
                <h2>מטפלים, מורים, מאמנים</h2>
                <ul>
                    <li>אנשי מקצוע שעוסקים בעזרה לאחרים ורוצים לקבל בעצמם, את מה שהם נותנים לאחרים...</li>
                    <li>אנשי מקצוע שרוצים להעמיק בכיוונים שהם כבר צועדים בם, ו/או לגלות כלים נוספים או לטייב את מה שכבר
                        בשימושם
                    </li>
                    <li>
                        <a href="/lib/education">מורים</a>
                        וכדומה שרוצים ללמוד איך לנהל את האנרגיה בצורה שתטיב יותר עם תפקידם, ויחד עם זאת תמלא אותם ולא
                        תרוקן אותם
                    </li>
                    <p>
                        <a href="/plans">ראו תכניות ומחירים</a>
                    </p>
                </ul>
            </div>
        </section>
    </article>
    <style>
        @media (min-width: 1100px) {
            :host article section {
                display: flex;
                flex-wrap: wrap;
            }
        }

        :host h2 {
            text-align: center;
        }

        :host section > div {
            margin: 5px;
            max-width:15em;
        }
    </style>
    <script>

        export default {}
    </script>

</for-whom>