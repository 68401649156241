<receive-updates>

    <modal-dialog isOpen={true} placing="bottom">
        <div>

            על ידי האישור, תקבלו פשוט עדכונים לדפדפן על פעילויות ומאמרים חדשים. אין צורך בפרטים, או בכלום...

        </div>
        <div>
            <button onclick={register}>כן, אני מעוניין/ת </button>
        </div>
    </modal-dialog>


    <script>

        import ModalDialog from './modal-dialog.riot'

        export default {
            components: {
                ModalDialog
            },

            register() {
                alert(1)
            },


        }

    </script>

</receive-updates>