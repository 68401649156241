<modal-dialog>

    <div class="modal-overlay" if={state.isOpen} onclick={closeOnOverlay}>
        <div class="modal-wrapper {props.placing}">
            <div class="modal-content {props.placing}" onclick={preventClose}>
                <slot></slot>
            </div>
<!--            <button class="close-button" onclick={closeModal}>X</button>-->
        </div>
    </div>

    <style>

        .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--background-color);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
        }

        .bottom {
            position: fixed;
            bottom: 0;

        }

        .modal-wrapper {
            display: flex;
            background: var(--opaque-background-color);
            padding: var(--standard-space);
            border-radius: 1em;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
            max-width: 90vh;
        }

        .close-button {
            flex-basis: content;
            align-self: flex-end;
            background: var(--primary-color);
            color: var(--background-color);
            border: none;
            font-size: 1.2rem;
            border-radius: 100%;
            cursor: pointer;
        }

        .modal-content {
            padding: 2em;
            justify-self: center;
            border: 1px solid var(--secondary-color);
        }
    </style>

    <script>

        export default {


            onMounted() {

                this.state.isOpen = this.props.isOpen === 'true' || this.open === true ||  this.isOpen !== 'false' && this.isOpen !== false
            },

            closeModal(e) {
                e.stopPropagation();
                this.update({isOpen: false});
            },

            closeOnOverlay(e) {
                this.closeModal(e);
            },

        }
    </script>
</modal-dialog>
